import React from 'react';
import {Tooltip} from './Tooltip';
import {formatNum, calculateSavingsPercentagesForType} from '../utils';

export function ResultRows({type, icon, tooltip, unit, results, countriesOptions}) {
  let renderedTooltip = type;
  if (tooltip !== '') {
    renderedTooltip = <Tooltip label={type} content={tooltip} />;
  }

  const renderSavingsSparkline = (countryCode, countryPercentages) => {
    if (!countryPercentages.hasOwnProperty(countryCode)) {
      return '';
    }
    const countrySavingsPct = countryPercentages[countryCode];
    return (
      <div className={`result--sparkline sort-order--${countrySavingsPct.sortOrder}`} style={{width: `${countrySavingsPct.pct}%`}}></div>
    );
  }

  const renderRows = () => {
    const rows = [];
    const countryPercentages = calculateSavingsPercentagesForType(type, results);
    Object.entries(results).forEach(([countryCode, countryResults]) => {
      const countryInfo = countriesOptions.find(country => country.value === countryCode);
      rows.push(
        <tr key={countryCode}>
          <td className='result--icon'></td>
          <td className='result--label'>{countryInfo.label}</td>
          <td data-label='Initial Environmental Impact' className='result--initial'>{formatNum(countryResults[type]['Initial'])}</td>
          <td data-label='' className='result--minus'>-</td>
          <td data-label='Recycling Environmental Impact' className='result--recycling'>{formatNum(countryResults[type]['Recycling'])}</td>
          <td data-label='' className='result--equals'>=</td>
          <td data-label='Environmental Savings' className='result--savings'>
            <div className='result--savings-wrapper'>
              {renderSavingsSparkline(countryCode, countryPercentages)}
              <div className='result--savings-value'>
                {formatNum(countryResults[type]['Savings'])} {unit}
              </div>
            </div>
          </td>
        </tr>
      );
    });
    return rows;
  }

  return (
    <>
      <tr className='result--header'>
        <td className='result--icon'><img src={icon} /></td>
        <td className='result--label tooltip-link'>{renderedTooltip}</td>
        <td data-label='Initial Environmental Impact' className='result--initial'></td>
        <td data-label='' className='result--minus'></td>
        <td data-label='Recycling Environmental Impact' className='result--recycling'></td>
        <td data-label='' className='result--equals'></td>
        <td data-label='Environmental Savings' className='result--savings'></td>
      </tr>
      {renderRows()}
    </>
  );
}
