export const MY = {
  code: "MY",
  name: "Malaysia",
  benchmarkOptions: [
    "Default EOL Fates",
    "Sanitary Landfill",
    "Open Dump",
    "Open Burning",
    "Incineration",
  ],
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.430628756039405,
          "Energy Consumption": 0.511055311169661,
          "Water Consumption": 0.000191034487502
        },
        "Consequential": {
          "Carbon Footprint": 0.430628756039405,
          "Energy Consumption": 0.511055311169661,
          "Water Consumption": 0.000191034487502
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.144747110600032,
          "Energy Consumption": 0.774772910300292,
          "Water Consumption": 0.0000635731139605242
        },
        "Consequential": {
          "Carbon Footprint": 0.144747110600032,
          "Energy Consumption": 0.774772910300292,
          "Water Consumption": 0.0000635731139605242
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.154050489273102,
          "Energy Consumption": 0.459260838617701,
          "Water Consumption": 0.0000322897604821079
        },
        "Consequential": {
          "Carbon Footprint": 0.154050489273102,
          "Energy Consumption": 0.459260838617701,
          "Water Consumption": 0.0000322897604821079
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.68943754400204,
          "Energy Consumption": 0.348902062236867,
          "Water Consumption": 0.001008054855395
        },
        "Consequential": {
          "Carbon Footprint": 2.68943754400204,
          "Energy Consumption": 0.348902062236867,
          "Water Consumption": 0.001008054855395
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.60464863508935,
          "Energy Consumption": 0.849089931961957,
          "Water Consumption": 0.002007511341505
        },
        "Consequential": {
          "Carbon Footprint": 2.60464863508935,
          "Energy Consumption": 0.849089931961957,
          "Water Consumption": 0.002007511341505
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.271847421243236,
          "Energy Consumption": 3.91901034571055,
          "Water Consumption": 0.001852339956799
        },
        "Consequential": {
          "Carbon Footprint": -0.712098298859479,
          "Energy Consumption": -35.6640812042894,
          "Water Consumption": -0.005847773873616
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.501942826335063,
          "Energy Consumption": 0.513064032871698,
          "Water Consumption": 0.000191135904324
        },
        "Consequential": {
          "Carbon Footprint": 0.501942826335063,
          "Energy Consumption": 0.513064032871698,
          "Water Consumption": 0.000191135904324
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.162999907904825,
          "Energy Consumption": 0.775443008294257,
          "Water Consumption": 0.0000637344325908577
        },
        "Consequential": {
          "Carbon Footprint": 0.162999907904825,
          "Energy Consumption": 0.775443008294257,
          "Water Consumption": 0.0000637344325908577
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.176231033838727,
          "Energy Consumption": 0.458749513355042,
          "Water Consumption": 0.000032245888264024
        },
        "Consequential": {
          "Carbon Footprint": 0.176231033838727,
          "Energy Consumption": 0.458749513355042,
          "Water Consumption": 0.000032245888264024
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.15612547237011,
          "Energy Consumption": 0.348741392152704,
          "Water Consumption": 0.001005544882671
        },
        "Consequential": {
          "Carbon Footprint": 3.15612547237011,
          "Energy Consumption": 0.348741392152704,
          "Water Consumption": 0.001005544882671
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.07680069043055,
          "Energy Consumption": 0.899212718768283,
          "Water Consumption": 0.002013483718873
        },
        "Consequential": {
          "Carbon Footprint": 3.07680069043055,
          "Energy Consumption": 0.899212718768283,
          "Water Consumption": 0.002013483718873
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.271835272554431,
          "Energy Consumption": 3.91884967562639,
          "Water Consumption": 0.001852330003112
        },
        "Consequential": {
          "Carbon Footprint": -0.735254425191749,
          "Energy Consumption": -35.0045729743736,
          "Water Consumption": -0.006904239072607
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.501732383877821,
          "Energy Consumption": 0.51005542519661,
          "Water Consumption": 0.000190895212314
        },
        "Consequential": {
          "Carbon Footprint": 0.501732383877821,
          "Energy Consumption": 0.51005542519661,
          "Water Consumption": 0.000190895212314
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.163002061534052,
          "Energy Consumption": 0.775720480131753,
          "Water Consumption": 0.0000638115998198624
        },
        "Consequential": {
          "Carbon Footprint": 0.163002061534052,
          "Energy Consumption": 0.775720480131753,
          "Water Consumption": 0.0000638115998198624
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.175960237832595,
          "Energy Consumption": 0.454808026040377,
          "Water Consumption": 0.0000319149597236961
        },
        "Consequential": {
          "Carbon Footprint": 0.175960237832595,
          "Energy Consumption": 0.454808026040377,
          "Water Consumption": 0.0000319149597236961
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.15601605219209,
          "Energy Consumption": 0.347294277201045,
          "Water Consumption": 0.001005455232316
        },
        "Consequential": {
          "Carbon Footprint": 3.15601605219209,
          "Energy Consumption": 0.347294277201045,
          "Water Consumption": 0.001005455232316
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.0768433459421,
          "Energy Consumption": 0.900116224734222,
          "Water Consumption": 0.002013621441768
        },
        "Consequential": {
          "Carbon Footprint": 3.0768433459421,
          "Energy Consumption": 0.900116224734222,
          "Water Consumption": 0.002013621441768
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.271725852376406,
          "Energy Consumption": 3.91740256067472,
          "Water Consumption": 0.001852240352757
        },
        "Consequential": {
          "Carbon Footprint": -0.766727936022151,
          "Energy Consumption": -36.1435082393254,
          "Water Consumption": -0.010093899474329
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.353992625345217,
          "Energy Consumption": 0.514924972272446,
          "Water Consumption": 0.000138008473992
        },
        "Consequential": {
          "Carbon Footprint": 0.353992625345217,
          "Energy Consumption": 0.514924972272446,
          "Water Consumption": 0.000138008473992
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.125692743690874,
          "Energy Consumption": 0.780044960152261,
          "Water Consumption": 0.0000646896785728355
        },
        "Consequential": {
          "Carbon Footprint": 0.125692743690874,
          "Energy Consumption": 0.780044960152261,
          "Water Consumption": 0.0000646896785728355
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.129756110335572,
          "Energy Consumption": 0.460792154703717,
          "Water Consumption": 0.0000324406527422707
        },
        "Consequential": {
          "Carbon Footprint": 0.129756110335572,
          "Energy Consumption": 0.460792154703717,
          "Water Consumption": 0.0000324406527422707
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.17683157747472,
          "Energy Consumption": 0.348822426620817,
          "Water Consumption": 0.000530649953805
        },
        "Consequential": {
          "Carbon Footprint": 2.17683157747472,
          "Energy Consumption": 0.348822426620817,
          "Water Consumption": 0.000530649953805
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.11873660715425,
          "Energy Consumption": 0.892910778248385,
          "Water Consumption": 0.001524915297924
        },
        "Consequential": {
          "Carbon Footprint": 2.11873660715425,
          "Energy Consumption": 0.892910778248385,
          "Water Consumption": 0.001524915297924
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.273077295304416,
          "Energy Consumption": 3.93158383953973,
          "Water Consumption": 0.001863414316863
        },
        "Consequential": {
          "Carbon Footprint": -1.17918216627097,
          "Energy Consumption": -36.3785549104602,
          "Water Consumption": -0.022004398634632
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.461543951983759,
          "Energy Consumption": 0.511893627640307,
          "Water Consumption": 0.000184296629419
        },
        "Consequential": {
          "Carbon Footprint": 0.461543951983759,
          "Energy Consumption": 0.511893627640307,
          "Water Consumption": 0.000184296629419
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.152745891882545,
          "Energy Consumption": 0.775898369859133,
          "Water Consumption": 0.0000638274349239131
        },
        "Consequential": {
          "Carbon Footprint": 0.152745891882545,
          "Energy Consumption": 0.775898369859133,
          "Water Consumption": 0.0000638274349239131
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.163553155349731,
          "Energy Consumption": 0.458116200387728,
          "Water Consumption": 0.0000321959530321154
        },
        "Consequential": {
          "Carbon Footprint": 0.163553155349731,
          "Energy Consumption": 0.458116200387728,
          "Water Consumption": 0.0000321959530321154
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.89067928283799,
          "Energy Consumption": 0.348415768657834,
          "Water Consumption": 0.000945990768741
        },
        "Consequential": {
          "Carbon Footprint": 2.89067928283799,
          "Energy Consumption": 0.348415768657834,
          "Water Consumption": 0.000945990768741
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.8124381218693,
          "Energy Consumption": 0.883499158945337,
          "Water Consumption": 0.001949689671698
        },
        "Consequential": {
          "Carbon Footprint": 2.8124381218693,
          "Energy Consumption": 0.883499158945337,
          "Water Consumption": 0.001949689671698
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.271967551416099,
          "Energy Consumption": 3.92013039943683,
          "Water Consumption": 0.001853716375177
        },
        "Consequential": {
          "Carbon Footprint": -0.792973673463136,
          "Energy Consumption": -35.6809973925896,
          "Water Consumption": -0.009349309060028
        }
      }
    }
  }
};
