import React, {useState, useRef} from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import {
  getDefaultFormValues,
  getBenchmarkOptionsForSelectedCountries,
  getCountriesDataForSelectedCountries,
  setFormValuesUrlParams,
} from './utils';
import {countries} from './countries';
import {plasticTypes} from './plastic-types';
import {Tooltip} from './components/Tooltip';
import {PlasticTypeForm} from './components/PlasticTypeForm';
import {Results} from './components/Results';

function App() {
  const resultsWrapperRef = useRef();
  const countriesOptions = Object.entries(countries).map(([countryCode, countryInfo]) => ({value: countryCode, label: countryInfo.name}));
  const defaultFormValues = getDefaultFormValues(countriesOptions, plasticTypes);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [resultFormValues, setResultFormValues] = useState(structuredClone(defaultFormValues));
  const benchmarkOptions = getBenchmarkOptionsForSelectedCountries(countries, formValues.countries);

  const handleCountryChange = (countryValues) => {
    countryValues.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    const updatedFormValues = structuredClone(formValues);
    updatedFormValues.countries = countryValues;
    setFormValues(updatedFormValues);
  }

  const handleCalculateButtonClick = (e) => {
    e.preventDefault();
    setResultFormValues(structuredClone(formValues));
    setFormValuesUrlParams(formValues);
    setTimeout(() => {
      const rect = resultsWrapperRef.current.getBoundingClientRect();
      window.scrollTo({
        top: window.pageYOffset + rect.top - 110,
        behavior: 'smooth',
      });
    }, 100);
  }

  const plasticTypeForms = Object.entries(plasticTypes).map(([plasticType, plasticTypeInfo]) => {
    return (
      <PlasticTypeForm
        key={plasticType}
        plasticType={plasticType}
        plasticTypeTooltip={plasticTypeInfo.description}
        benchmarkOptions={benchmarkOptions}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    );
  });

  const renderInvestmentTonnageTooltip = () => {
    return <Tooltip content='Indicates the weight (in metric tonnes) of the various types of polymers diverted from different end-of-life fates due to the current/proposed recycling intervention.' placement='bottom' />;
  }

  const renderDivertingTooltip = () => {
    const tooltipContent = `
      Default end-of-life (EOL) fates indicate the current end-of-life for plastic waste by mapping material flow based on existing data. Select this option if the EOL fate is unknown. For other EOL fates please select the relevant option.<br />When comparing multiple countries and an EOL fate is not available for a certain country selected, the calculator would default to the "Default EOL fates" value for that country.
    `;
    return <Tooltip content={tooltipContent} placement='bottom' multiline={true} />;
  }

  return (
    <div className='ci-calculator'>
      <div className='field--country-select'>
        <label>Please select countries: </label>
        <Select
          className='autocomplete-wrapper'
          classNamePrefix='autocomplete'
          defaultValue={formValues.countries}
          options={countriesOptions}
          onChange={handleCountryChange}
          isMulti
        />
      </div>
      <table className='calculator'>
        <thead>
          <tr>
            <td className='header--plastic-type'>What type of plastic is being recycled?</td>
            <td data-label='Metric Tonnes' className='header--tonnage'><div>How many metric tonnes of this plastic are you diverting towards recycling? (Round to the nearest whole number.) {renderInvestmentTonnageTooltip()}</div></td>
            <td data-label='End-of-life Fate' className='header--benchmark'><div>Where is the plastic expected to end up if not recycled? (Select Default EOL Fates if unknown.) {renderDivertingTooltip()}</div></td>
          </tr>
        </thead>
        <tbody>
          {plasticTypeForms}
        </tbody>
      </table>
      <div className='calculate-button--wrapper'>
        <a className='button' href='#' onClick={handleCalculateButtonClick}>Calculate</a>
      </div>
      <div id='results-wrapper' ref={resultsWrapperRef}>
        <Results
          countries={countries}
          countriesOptions={countriesOptions}
          resultFormValues={resultFormValues}
        />
      </div>
      <ReactTooltip
        textColor='white'
        backgroundColor='#1E628C'
        place='right'
      />
    </div>
  );
}

export default App;
