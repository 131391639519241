import React from 'react';
import ReactTooltip from 'react-tooltip';
import {calculateResults, getCountriesDataForSelectedCountries} from '../utils';
import {Tooltip} from './Tooltip';
import {ResultRows} from './ResultRows';
import {ResultsSummary} from './ResultsSummary';

export function Results({countries, countriesOptions, resultFormValues}) {
  if (!resultFormValues || resultFormValues.countries.length === 0) {
    return '';
  }
  const countriesData = getCountriesDataForSelectedCountries(countries, resultFormValues.countries);
  const results = calculateResults(resultFormValues, countriesData);

  return (
    <div className='results'>
      <ResultsSummary
        results={results}
        countriesOptions={countriesOptions}
      />
      <table>
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td>
              Initial Impact <Tooltip content='Environmental impact of plastic waste going to the original EOL fate.' placement='bottom' />
            </td>
            <td></td>
            <td>
              Recycling Impact <Tooltip content='Environmental impact of recycling the plastic waste, including impact from waste processing, transport, and savings from avoided production.' placement='bottom' />
            </td>
            <td></td>
            <td>
              Environmental Savings <Tooltip content='Cumulative environmental impact of the avoided impact from diverting plastic waste from the original EOL fate and the recycling impact.' placement='bottom' />
            </td>
          </tr>
        </thead>
        <tbody>
          <ResultRows
            type='Carbon Footprint'
            icon='/images/icon-carbon.svg'
            tooltip='Measures the greenhouse gas emissions avoided from diverted plastic waste from the environment.'
            unit={<span>tonnes CO<sub>2</sub>-eq</span>}
            results={results}
            countriesOptions={countriesOptions}
          />
          <ResultRows
            type='Energy Consumption'
            icon='images/icon-energy.svg'
            tooltip='Measures the use of different renewable and non-renewable energy sources, including fossil fuels and biomass.'
            unit='MJ'
            results={results}
            countriesOptions={countriesOptions}
          />
          <ResultRows
            type='Water Consumption'
            icon='images/icon-water.svg'
            tooltip='Measures the amount of water that is evaporated, disposed into water bodies or incorporated in products.'
            unit='m&sup3;'
            results={results}
            countriesOptions={countriesOptions}
          />
        </tbody>
      </table>
      <ReactTooltip
        textColor='white'
        backgroundColor='#1E628C'
        place='right'
      />
    </div>
  );
}
