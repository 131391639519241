export const ID = {
  code: "ID",
  name: "Indonesia",
  benchmarkOptions: [
    "Default EOL Fates",
    "Sanitary Landfill",
    "Open Dump",
    "Open Burning",
  ],
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.48813685106832,
          "Energy Consumption": 0.0891646744334495,
          "Water Consumption": 0.000538096316553642
        },
        "Consequential": {
          "Carbon Footprint": 1.48813685106832,
          "Energy Consumption": 0.0891646744334495,
          "Water Consumption": 0.000538096316553642
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.114408099937562,
          "Energy Consumption": 0.361507045806305,
          "Water Consumption": 0.000035093818639226
        },
        "Consequential": {
          "Carbon Footprint": 0.114408099937562,
          "Energy Consumption": 0.361507045806305,
          "Water Consumption": 0.000035093818639226
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.125964929383725,
          "Energy Consumption": 0.0808984744124399,
          "Water Consumption": 0.00000718231811615353
        },
        "Consequential": {
          "Carbon Footprint": 0.125964929383725,
          "Energy Consumption": 0.0808984744124399,
          "Water Consumption": 0.00000718231811615353
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.66445392965685,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000987585231473111
        },
        "Consequential": {
          "Carbon Footprint": 2.66445392965685,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000987585231473111
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.263946752983744,
          "Energy Consumption": 3.72373533040543,
          "Water Consumption": 0.00199831346694563
        },
        "Consequential": {
          "Carbon Footprint": -0.725356317281601,
          "Energy Consumption": -35.9374503623347,
          "Water Consumption": -0.0056955161667814
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.74870508495545,
          "Energy Consumption": 0.0912361056836855,
          "Water Consumption": 0.000536967098049306
        },
        "Consequential": {
          "Carbon Footprint": 1.74870508495545,
          "Energy Consumption": 0.0912361056836855,
          "Water Consumption": 0.000536967098049306
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.132935767887868,
          "Energy Consumption": 0.366387887189113,
          "Water Consumption": 0.0000356574928879691
        },
        "Consequential": {
          "Carbon Footprint": 0.132935767887868,
          "Energy Consumption": 0.366387887189113,
          "Water Consumption": 0.0000356574928879691
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.148457519141432,
          "Energy Consumption": 0.085183293808186,
          "Water Consumption": 0.00000759678448293908
        },
        "Consequential": {
          "Carbon Footprint": 0.148457519141432,
          "Energy Consumption": 0.085183293808186,
          "Water Consumption": 0.00000759678448293908
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.13115400671373,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000985085212436877
        },
        "Consequential": {
          "Carbon Footprint": 3.13115400671373,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000985085212436877
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.263946752983744,
          "Energy Consumption": 3.72373533040543,
          "Water Consumption": 0.00199831346694563
        },
        "Consequential": {
          "Carbon Footprint": -0.749808121281204,
          "Energy Consumption": -35.2917154439193,
          "Water Consumption": -0.00675147790015644
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.74849343487169,
          "Energy Consumption": 0.0879646484607948,
          "Water Consumption": 0.000536650653209037
        },
        "Consequential": {
          "Carbon Footprint": 1.74849343487169,
          "Energy Consumption": 0.0879646484607948,
          "Water Consumption": 0.000536650653209037
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.132478358187924,
          "Energy Consumption": 0.359317744621249,
          "Water Consumption": 0.0000349736049496192
        },
        "Consequential": {
          "Carbon Footprint": 0.132478358187924,
          "Energy Consumption": 0.359317744621249,
          "Water Consumption": 0.0000349736049496192
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.148000109441488,
          "Energy Consumption": 0.0781131512403218,
          "Water Consumption": 0.00000691289654458917
        },
        "Consequential": {
          "Carbon Footprint": 0.148000109441488,
          "Energy Consumption": 0.0781131512403218,
          "Water Consumption": 0.00000691289654458917
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.13115400671373,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000985085212436877
        },
        "Consequential": {
          "Carbon Footprint": 3.13115400671373,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000985085212436877
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.263946752983744,
          "Energy Consumption": 3.72373533040543,
          "Water Consumption": 0.00199831346694563
        },
        "Consequential": {
          "Carbon Footprint": -0.789859153038607,
          "Energy Consumption": -36.5084389197533,
          "Water Consumption": -0.00994513332518314
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.2023064985583,
          "Energy Consumption": 0.0910701938813591,
          "Water Consumption": 0.000281871651366664
        },
        "Consequential": {
          "Carbon Footprint": 1.2023064985583,
          "Energy Consumption": 0.0910701938813591,
          "Water Consumption": 0.000281871651366664
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.0955793732072573,
          "Energy Consumption": 0.37012857816731,
          "Water Consumption": 0.000036539438619674
        },
        "Consequential": {
          "Carbon Footprint": 0.0955793732072573,
          "Energy Consumption": 0.37012857816731,
          "Water Consumption": 0.000036539438619674
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.101700452618907,
          "Energy Consumption": 0.0828785482589656,
          "Water Consumption": 0.00000737384871227419
        },
        "Consequential": {
          "Carbon Footprint": 0.101700452618907,
          "Energy Consumption": 0.0828785482589656,
          "Water Consumption": 0.00000737384871227419
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.15185398458849,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000510185263396613
        },
        "Consequential": {
          "Carbon Footprint": 2.15185398458849,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000510185263396613
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.263946752983744,
          "Energy Consumption": 3.72373533040543,
          "Water Consumption": 0.00199831346694563
        },
        "Consequential": {
          "Carbon Footprint": -1.1901642572375,
          "Energy Consumption": -36.6391094998207,
          "Water Consumption": -0.0218278073892383
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.55841317756664,
          "Energy Consumption": 0.0895796529550053,
          "Water Consumption": 0.000497467281292457
        },
        "Consequential": {
          "Carbon Footprint": 1.55841317756664,
          "Energy Consumption": 0.0895796529550053,
          "Water Consumption": 0.000497467281292457
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.119531027295272,
          "Energy Consumption": 0.363286242665739,
          "Water Consumption": 0.0000354019462092583
        },
        "Consequential": {
          "Carbon Footprint": 0.119531027295272,
          "Energy Consumption": 0.363286242665739,
          "Water Consumption": 0.0000354019462092583
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.13198816475218,
          "Energy Consumption": 0.0813763944891144,
          "Water Consumption": 0.00000722854685522006
        },
        "Consequential": {
          "Carbon Footprint": 0.13198816475218,
          "Energy Consumption": 0.0813763944891144,
          "Water Consumption": 0.00000722854685522006
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.79031512168046,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000911853733808378
        },
        "Consequential": {
          "Carbon Footprint": 2.79031512168046,
          "Energy Consumption": 0.018486204945225,
          "Water Consumption": 0.000911853733808378
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.263946752983744,
          "Energy Consumption": 3.72373533040543,
          "Water Consumption": 0.00199831346694563
        },
        "Consequential": {
          "Carbon Footprint": -0.818568031422313,
          "Energy Consumption": -36.0586099150972,
          "Water Consumption": -0.00946164934146625
        }
      }
    }
  }
};
