import React from 'react';
import {Tooltip} from './Tooltip';

export function PlasticTypeForm({plasticType, plasticTypeTooltip, benchmarkOptions, formValues, setFormValues}) {
  const benchmarkSelectOptions = benchmarkOptions.map(option => <option key={option} value={option}>{option}</option>);
  const values = formValues.plasticTypes[plasticType];

  const handleChange = (el) => {
    let value = el.value;
    if (el.name === 'tonnage') {
      value = parseInt(value);
      if (value < 0) {
        value = 0;
      }
    }
    const updatedFormValues = Object.assign({}, formValues);
    updatedFormValues.plasticTypes[plasticType][el.name] = value;
    setFormValues(updatedFormValues);
  }

  const renderTooltip = () => {
    if (plasticTypeTooltip !== '') {
      return <Tooltip label={plasticType} content={plasticTypeTooltip} />;
    }
  }

  return (
    <tr>
      <td className='field field--plastic-type'>
        <div className='tooltip-link'>
          {renderTooltip()}
        </div>
      </td>
      <td data-label='Metric Tonnes' className='field field--tonnage'>
        <input type="number" name="tonnage" value={values.tonnage} onChange={(e) => {handleChange(e.target)}} step="1" autoComplete='off' />
      </td>
      <td data-label='End-of-life Fate' className='field field--benchmark'>
        <select name="benchmark" value={values.benchmark} onChange={(e) => {handleChange(e.target)}}>
          {benchmarkSelectOptions}
        </select>
      </td>
    </tr>
  );
}
