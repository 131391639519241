export const VN = {
  code: "VN",
  name: "Vietnam",
  benchmarkOptions: [
    "Default EOL Fates",
    "Sanitary Landfill",
    "Open Dump",
    "Open Burning",
    "Incineration",
  ],
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.31173134740923,
          "Energy Consumption": 0.094219055227986,
          "Water Consumption": 0.000514640894216
        },
        "Consequential": {
          "Carbon Footprint": 1.31173134740923,
          "Energy Consumption": 0.094219055227986,
          "Water Consumption": 0.000514640894216
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.119174557688758,
          "Energy Consumption": 0.436902524635336,
          "Water Consumption": 0.0000427260025613569
        },
        "Consequential": {
          "Carbon Footprint": 0.119174557688758,
          "Energy Consumption": 0.436902524635336,
          "Water Consumption": 0.0000427260025613569
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.125154662217724,
          "Energy Consumption": 0.070124239523806,
          "Water Consumption": 0.00000650082124807348
        },
        "Consequential": {
          "Carbon Footprint": 0.125154662217724,
          "Energy Consumption": 0.070124239523806,
          "Water Consumption": 0.00000650082124807348
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.66366974553134,
          "Energy Consumption": 0.008115133250561,
          "Water Consumption": 0.000986942732197
        },
        "Consequential": {
          "Carbon Footprint": 2.66366974553134,
          "Energy Consumption": 0.008115133250561,
          "Water Consumption": 0.000986942732197
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.57914475959141,
          "Energy Consumption": 0.512382437776697,
          "Water Consumption": 0.001986793818024
        },
        "Consequential": {
          "Carbon Footprint": 2.57914475959141,
          "Energy Consumption": 0.512382437776697,
          "Water Consumption": 0.001986793818024
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.246877074185517,
          "Energy Consumption": 3.56519803836167,
          "Water Consumption": 0.001848155495509
        },
        "Consequential": {
          "Carbon Footprint": -0.730105985509887,
          "Energy Consumption": -35.8597487616383,
          "Water Consumption": -0.005828574107218
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.58847134182814,
          "Energy Consumption": 0.087507313998476,
          "Water Consumption": 0.000537593073692
        },
        "Consequential": {
          "Carbon Footprint": 1.58847134182814,
          "Energy Consumption": 0.087507313998476,
          "Water Consumption": 0.000537593073692
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.136926627238604,
          "Energy Consumption": 0.431160062676651,
          "Water Consumption": 0.000042541135220347
        },
        "Consequential": {
          "Carbon Footprint": 0.136926627238604,
          "Energy Consumption": 0.431160062676651,
          "Water Consumption": 0.000042541135220347
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.146541437468088,
          "Energy Consumption": 0.058685910605687,
          "Water Consumption": 0.00000567663041376952
        },
        "Consequential": {
          "Carbon Footprint": 0.146541437468088,
          "Energy Consumption": 0.058685910605687,
          "Water Consumption": 0.00000567663041376952
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971775
        },
        "Consequential": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971775
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.05076230758623,
          "Energy Consumption": 0.55558561099033,
          "Water Consumption": 0.001992373507675
        },
        "Consequential": {
          "Carbon Footprint": 3.05076230758623,
          "Energy Consumption": 0.55558561099033,
          "Water Consumption": 0.001992373507675
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.246942882346414,
          "Energy Consumption": 3.56403361090141,
          "Water Consumption": 0.001853745808797
        },
        "Consequential": {
          "Carbon Footprint": -0.751586593036371,
          "Energy Consumption": -35.1649583890986,
          "Water Consumption": -0.006874073596953
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.7415138200433,
          "Energy Consumption": 0.40262237182882,
          "Water Consumption": 0.000670287899936
        },
        "Consequential": {
          "Carbon Footprint": 1.7415138200433,
          "Energy Consumption": 0.40262237182882,
          "Water Consumption": 0.000670287899936
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.158558562887447,
          "Energy Consumption": 0.718006357182445,
          "Water Consumption": 0.0000604940231058517
        },
        "Consequential": {
          "Carbon Footprint": 0.158558562887447,
          "Energy Consumption": 0.718006357182445,
          "Water Consumption": 0.0000604940231058517
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.167580260722345,
          "Energy Consumption": 0.336364517592445,
          "Water Consumption": 0.0000227427369022169
        },
        "Consequential": {
          "Carbon Footprint": 0.167580260722345,
          "Energy Consumption": 0.336364517592445,
          "Water Consumption": 0.0000227427369022169
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.15104875463014,
          "Energy Consumption": 0.281600264442445,
          "Water Consumption": 0.001001385416332
        },
        "Consequential": {
          "Carbon Footprint": 3.15104875463014,
          "Energy Consumption": 0.281600264442445,
          "Water Consumption": 0.001001385416332
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.07231998603062,
          "Energy Consumption": 0.841284118242445,
          "Water Consumption": 0.002010215371228
        },
        "Consequential": {
          "Carbon Footprint": 3.07231998603062,
          "Energy Consumption": 0.841284118242445,
          "Water Consumption": 0.002010215371228
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.268235421015759,
          "Energy Consumption": 3.84563387534385,
          "Water Consumption": 0.001871191253354
        },
        "Consequential": {
          "Carbon Footprint": -0.751335428050671,
          "Energy Consumption": -35.7863836246562,
          "Water Consumption": -0.010011529862068
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.03271071325511,
          "Energy Consumption": 0.122144337785746,
          "Water Consumption": 0.000296126098696
        },
        "Consequential": {
          "Carbon Footprint": 1.03271071325511,
          "Energy Consumption": 0.122144337785746,
          "Water Consumption": 0.000296126098696
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.100937620906036,
          "Energy Consumption": 0.452932359759155,
          "Water Consumption": 0.0000445024545173841
        },
        "Consequential": {
          "Carbon Footprint": 0.100937620906036,
          "Energy Consumption": 0.452932359759155,
          "Water Consumption": 0.0000445024545173841
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.102051890796621,
          "Energy Consumption": 0.088375370815775,
          "Water Consumption": 0.0000079188801745736
        },
        "Consequential": {
          "Carbon Footprint": 0.102051890796621,
          "Energy Consumption": 0.088375370815775,
          "Water Consumption": 0.0000079188801745736
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.15182500462855,
          "Energy Consumption": 0.01810293677347,
          "Water Consumption": 0.000510161519479
        },
        "Consequential": {
          "Carbon Footprint": 2.15182500462855,
          "Energy Consumption": 0.01810293677347,
          "Water Consumption": 0.000510161519479
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.09410085599878,
          "Energy Consumption": 0.567923047535837,
          "Water Consumption": 0.001504981291022
        },
        "Consequential": {
          "Carbon Footprint": 2.09410085599878,
          "Energy Consumption": 0.567923047535837,
          "Water Consumption": 0.001504981291022
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.245911137898919,
          "Energy Consumption": 3.55757783406287,
          "Water Consumption": 0.00183333890407
        },
        "Consequential": {
          "Carbon Footprint": -1.20241673284754,
          "Energy Consumption": -36.6632616159371,
          "Water Consumption": -0.022021269724688
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.31661974682137,
          "Energy Consumption": 0.181951858000284,
          "Water Consumption": 0.000436013389734
        },
        "Consequential": {
          "Carbon Footprint": 1.31661974682137,
          "Energy Consumption": 0.181951858000284,
          "Water Consumption": 0.000436013389734
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.125448560784285,
          "Energy Consumption": 0.527108713096515,
          "Water Consumption": 0.0000487392270060655
        },
        "Consequential": {
          "Carbon Footprint": 0.125448560784285,
          "Energy Consumption": 0.527108713096515,
          "Water Consumption": 0.0000487392270060655
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.130764697921882,
          "Energy Consumption": 0.156239129117888,
          "Water Consumption": 0.000011861683518705
        },
        "Consequential": {
          "Carbon Footprint": 0.130764697921882,
          "Energy Consumption": 0.156239129117888,
          "Water Consumption": 0.000011861683518705
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.65129792822763,
          "Energy Consumption": 0.093483842016163,
          "Water Consumption": 0.000805966081488
        },
        "Consequential": {
          "Carbon Footprint": 2.65129792822763,
          "Energy Consumption": 0.093483842016163,
          "Water Consumption": 0.000805966081488
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.57939010764082,
          "Energy Consumption": 0.636471583779201,
          "Water Consumption": 0.001807296060004
        },
        "Consequential": {
          "Carbon Footprint": 2.57939010764082,
          "Energy Consumption": 0.636471583779201,
          "Water Consumption": 0.001807296060004
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.252926215103968,
          "Energy Consumption": 3.64641511261219,
          "Water Consumption": 0.001849804818217
        },
        "Consequential": {
          "Carbon Footprint": -0.921646591257472,
          "Energy Consumption": -36.0901996507888,
          "Water Consumption": -0.013460070822948
        }
      }
    }
  }
};
