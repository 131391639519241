import React from 'react';
import {formatNum} from '../utils';

export function ResultsSummary({results, countriesOptions}) {
  const renderSummaryText = (type, unit) => {
    const summaryText = [];
    Object.entries(results).forEach(([countryCode, countryResults]) => {
      const countryInfo = countriesOptions.find(country => country.value === countryCode);
      summaryText.push(
        <span className='results-summary--list-element' key={countryCode}>
          {formatNum(countryResults[type]['Savings'])} {unit} in {countryInfo.label}
        </span>
      );
    });
    return summaryText;
  }

  return (
    <div className="results-summary">
      <div className='results-summary--icon'>
        <div className='results-summary--icon-heading'>Your Results</div>
        <div><img src='/images/icon-results.svg' /></div>
        </div>
        <div className='results-summary--text'>
          <p>An investment in recycling the selected plastic types and diverting these from their end-of-life fates will lead to the following results:</p>
          <ul className='results-summary--list'>
            <li>reductions in CO₂-eq atmospheric emissions of {renderSummaryText('Carbon Footprint', 'tonnes')}</li>
            <li>which includes both reducing energy usage of {renderSummaryText('Energy Consumption', 'MJ')}</li>
            <li>and water savings of {renderSummaryText('Water Consumption', 'm³')}</li>
          </ul>
        </div>
      </div>
  );
}
