export const IN = {
  code: "IN",
  name: "India",
  benchmarkOptions: [
    "Default EOL Fates",
    "Open Dump",
    "Open Burning",
    "Co-processing in Cement Kilns",
    "Incineration",
  ],
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.444641127695555,
          "Energy Consumption": 0.2556418392645,
          "Water Consumption": 0.00017644726247494
        },
        "Consequential": {
          "Carbon Footprint": 0.327278726663216,
          "Energy Consumption": -2.88428998179154,
          "Water Consumption": -0.000680590255924992
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.57275637119614,
          "Energy Consumption": 0.415006957834403,
          "Water Consumption": 0.00197765701488085
        },
        "Consequential": {
          "Carbon Footprint": 0.185375397933432,
          "Energy Consumption": -33.2729740496387,
          "Water Consumption": -0.0172065261258556
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.123327881733414,
          "Energy Consumption": 0.0432571817115642,
          "Water Consumption": 0.00000418422465114251
        },
        "Consequential": {
          "Carbon Footprint": 0.123327881733414,
          "Energy Consumption": 0.0432571817115642,
          "Water Consumption": 0.00000418422465114251
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.66305613890392,
          "Energy Consumption": 0,
          "Water Consumption": 0.00098643999081105
        },
        "Consequential": {
          "Carbon Footprint": 2.66305613890392,
          "Energy Consumption": 0,
          "Water Consumption": 0.00098643999081105
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.920667837777696,
          "Energy Consumption": 5.23253538994988,
          "Water Consumption": 0.000452349309754741
        },
        "Consequential": {
          "Carbon Footprint": 0.447582163435766,
          "Energy Consumption": -39.9112977425131,
          "Water Consumption": -0.00111847410667164
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.464442239810722,
          "Energy Consumption": 4.85478033821149,
          "Water Consumption": 0.00164695878907907
        },
        "Consequential": {
          "Carbon Footprint": -0.628514484779922,
          "Energy Consumption": -35.985883060073,
          "Water Consumption": -0.00637115609901709
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.512523821055268,
          "Energy Consumption": 0.264403768199093,
          "Water Consumption": 0.000174407552776791
        },
        "Consequential": {
          "Carbon Footprint": 0.366477268980847,
          "Energy Consumption": -3.26127552715905,
          "Water Consumption": -0.000913374728693
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.04489896956272,
          "Energy Consumption": 0.464956527067483,
          "Water Consumption": 0.00198360704261461
        },
        "Consequential": {
          "Carbon Footprint": -0.094218630017199,
          "Energy Consumption": -43.8287444612383,
          "Water Consumption": -0.0232205146800365
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.145745138061036,
          "Energy Consumption": 0.0463775787286718,
          "Water Consumption": 0.00000448605758624662
        },
        "Consequential": {
          "Carbon Footprint": 0.145745138061036,
          "Energy Consumption": 0.0463775787286718,
          "Water Consumption": 0.00000448605758624662
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971774816
        },
        "Consequential": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971774816
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.920667837777696,
          "Energy Consumption": 5.23253538994988,
          "Water Consumption": 0.000452349309754741
        },
        "Consequential": {
          "Carbon Footprint": 0.46341366114907,
          "Energy Consumption": -38.3988972437582,
          "Water Consumption": -0.00106594473340638
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.464442239810722,
          "Energy Consumption": 4.85478033821149,
          "Water Consumption": 0.00164695878907907
        },
        "Consequential": {
          "Carbon Footprint": -0.677271639932244,
          "Energy Consumption": -35.5859402132169,
          "Water Consumption": -0.00750855839046063
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.512523821055268,
          "Energy Consumption": 0.264403768199093,
          "Water Consumption": 0.000174407552776791
        },
        "Consequential": {
          "Carbon Footprint": 0.366010491042879,
          "Energy Consumption": -3.30586734020003,
          "Water Consumption": -0.000914923511567466
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.04489896956272,
          "Energy Consumption": 0.464956527067483,
          "Water Consumption": 0.00198360704261461
        },
        "Consequential": {
          "Carbon Footprint": -0.094218630017199,
          "Energy Consumption": -43.8287444612383,
          "Water Consumption": -0.0232205146800365
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.145745138061036,
          "Energy Consumption": 0.0463775787286718,
          "Water Consumption": 0.00000448605758624662
        },
        "Consequential": {
          "Carbon Footprint": 0.145745138061036,
          "Energy Consumption": 0.0463775787286718,
          "Water Consumption": 0.00000448605758624662
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971774816
        },
        "Consequential": {
          "Carbon Footprint": 3.1297562159608,
          "Energy Consumption": 0,
          "Water Consumption": 0.000983939971774816
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.920667837777696,
          "Energy Consumption": 5.23253538994988,
          "Water Consumption": 0.000452349309754741
        },
        "Consequential": {
          "Carbon Footprint": 0.451566962179931,
          "Energy Consumption": -39.5306255081326,
          "Water Consumption": -0.00110525242768651
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.464442239810722,
          "Energy Consumption": 4.85478033821149,
          "Water Consumption": 0.00164695878907907
        },
        "Consequential": {
          "Carbon Footprint": -0.875940156209315,
          "Energy Consumption": -38.3192428306389,
          "Water Consumption": -0.0112766781728629
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.245875856228538,
          "Energy Consumption": 0.501699477240911,
          "Water Consumption": 0.0000799459011280216
        },
        "Consequential": {
          "Carbon Footprint": 0.203328687780347,
          "Energy Consumption": -1.51448317156741,
          "Water Consumption": -0.000180585385794242
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.08694777213635,
          "Energy Consumption": 0.460413099681379,
          "Water Consumption": 0.00149521154177029
        },
        "Consequential": {
          "Carbon Footprint": 0.397247766430149,
          "Energy Consumption": -23.3876327889463,
          "Water Consumption": -0.0121359111066833
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.105946029683549,
          "Energy Consumption": 0.15162139647566,
          "Water Consumption": 0.0000146661885881601
        },
        "Consequential": {
          "Carbon Footprint": 0.105946029683549,
          "Energy Consumption": 0.15162139647566,
          "Water Consumption": 0.0000146661885881601
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.15045619383556,
          "Energy Consumption": 0,
          "Water Consumption": 0.000509040022734552
        },
        "Consequential": {
          "Carbon Footprint": 2.15045619383556,
          "Energy Consumption": 0,
          "Water Consumption": 0.000509040022734552
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.920667837777696,
          "Energy Consumption": 5.23253538994988,
          "Water Consumption": 0.000452349309754741
        },
        "Consequential": {
          "Carbon Footprint": 0.667284580681614,
          "Energy Consumption": -18.9228826577522,
          "Water Consumption": -0.000389495049112984
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.464442239810722,
          "Energy Consumption": 4.85478033821149,
          "Water Consumption": 0.00164695878907907
        },
        "Consequential": {
          "Carbon Footprint": -1.04041425723782,
          "Energy Consumption": -36.2281553885125,
          "Water Consumption": -0.0221079213295329
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.415433342892228,
          "Energy Consumption": 0.316601013999668,
          "Water Consumption": 0.000147255857358991
        },
        "Consequential": {
          "Carbon Footprint": 0.310156239988742,
          "Energy Consumption": -2.55281947952741,
          "Water Consumption": -0.000618917196896814
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.55315792740578,
          "Energy Consumption": 0.448247657920634,
          "Water Consumption": 0.00180364286758975
        },
        "Consequential": {
          "Carbon Footprint": 0.169323424974641,
          "Energy Consumption": -33.1908485209873,
          "Water Consumption": -0.017364542808597
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.124471023282189,
          "Energy Consumption": 0.0838312328444168,
          "Water Consumption": 0.00000810891271979246
        },
        "Consequential": {
          "Carbon Footprint": 0.124471023282189,
          "Energy Consumption": 0.0838312328444168,
          "Water Consumption": 0.00000810891271979246
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.63186692675072,
          "Energy Consumption": 0,
          "Water Consumption": 0.000811445366426843
        },
        "Consequential": {
          "Carbon Footprint": 2.63186692675072,
          "Energy Consumption": 0,
          "Water Consumption": 0.000811445366426843
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.920667837777696,
          "Energy Consumption": 5.23253538994988,
          "Water Consumption": 0.000452349309754741
        },
        "Consequential": {
          "Carbon Footprint": 0.53084182718578,
          "Energy Consumption": -31.9574099131978,
          "Water Consumption": -0.000842216102895477
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.464442239810722,
          "Energy Consumption": 4.85478033821149,
          "Water Consumption": 0.00164695878907907
        },
        "Consequential": {
          "Carbon Footprint": -0.831537392452096,
          "Energy Consumption": -36.4426375164384,
          "Water Consumption": -0.0131837186415677
        }
      }
    }
  }
};
