export const plasticTypes = {
  'Generic': {
    description: 'All mixed plastic materials based on status-quo composition for the country',
  },
  'PP': {
    description: 'Polypropylene, commonly used in food containers, straws, automotive parts, bottle caps',
  },
  'HDPE': {
    description: 'High-density polyethylene, commonly used in milk bottles, detergent, shampoo bottles, juice bottles',
  },
  'LDPE': {
    description: 'Low-density polyethylene, commonly used in shopping and garbage bags, plastic wrapping, packaging films',
  },
  'PET': {
    description: 'Polyethylene terephthalate, commonly used in clear soft drink bottles, cups, cooking oil bottles',
  },
}
