import {IN} from './IN.js';
import {ID} from './ID.js';
import {MY} from './MY.js';
import {PH} from './PH.js';
import {TH} from './TH.js';
import {VN} from './VN.js';

export const countries = {
  IN,
  ID,
  MY,
  PH,
  TH,
  VN,
};
