export function getDefaultFormValues(countriesOptions, plasticTypes) {
  const defaultFormValues = {
    countries: [],
    plasticTypes: {},
  };
  const queryParams = new URL(location.href).searchParams;
  const countriesValue = queryParams.get('countries');
  if (countriesValue !== null) {
    const countries = countriesValue.split(',');
    countries.forEach(countryCode => {
      const country = countriesOptions.find(option => option.value === countryCode);
      if (country) {
        defaultFormValues.countries.push(country);
      }
    });
  }
  defaultFormValues.countries.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
  Object.keys(plasticTypes).forEach(plasticType => {
    const plasticTypeValues = {
      tonnage: 0,
      benchmark: "Default EOL Fates",
      scope: "Consequential",
    };
    ['tonnage', 'benchmark'].forEach(plasticTypeField => {
      const plasticTypeFieldValue = queryParams.get(plasticType + '_' + plasticTypeField);
      if (plasticTypeFieldValue !== null) {
        plasticTypeValues[plasticTypeField] = plasticTypeFieldValue;
      }
    });
    defaultFormValues.plasticTypes[plasticType] = plasticTypeValues;
  });
  return defaultFormValues;
}

export function setFormValuesUrlParams(formValues) {
  const url = new URL(location.origin + location.pathname);
  const countries = formValues.countries.map(country => country.value);
  url.searchParams.set('countries', countries.join(','));
  Object.entries(formValues.plasticTypes).forEach(([plasticType, {tonnage, benchmark}]) => {
      url.searchParams.set(plasticType + '_tonnage', tonnage);
      url.searchParams.set(plasticType + '_benchmark', benchmark);
  });
  history.pushState({}, '', url);
}

export function getBenchmarkOptionsForSelectedCountries(countries, selectedCountries) {
  let benchmarkOptions = ["Default EOL Fates"];
  selectedCountries.forEach(selectedCountry => {
    const countryBenchmarkOptions = countries[selectedCountry.value].benchmarkOptions;
    countryBenchmarkOptions.forEach(countryBenchmarkOption => {
      if (!benchmarkOptions.includes(countryBenchmarkOption)) {
        benchmarkOptions.push(countryBenchmarkOption);
      }
    });
  });
  return benchmarkOptions;
}

export function getCountriesDataForSelectedCountries(countries, selectedCountries) {
  const countriesData = {};
  selectedCountries.forEach(selectedCountry => {
    countriesData[selectedCountry.value] = countries[selectedCountry.value].data;
  });
  return countriesData;
}

export function calculateResults(formValues, countriesData) {
  const results = {};
  Object.entries(formValues.plasticTypes).forEach(([plasticType, {benchmark, scope, tonnage}]) => {
    Object.entries(countriesData).forEach(([countryCode, data]) => {
      const rowBenchmark = data[plasticType][benchmark] ? benchmark : "Default EOL Fates";
      const dataRow = data[plasticType][rowBenchmark][scope];
      const recyclingDataRow = data[plasticType]['Recycling'][scope];
      Object.entries(dataRow).forEach(([name, value]) => {
        calculateResultsForDataRow(countryCode, 'Initial', name, value, tonnage, results);
        calculateResultsForDataRow(countryCode, 'Recycling', name, recyclingDataRow[name], tonnage, results);
      });
    });
  });
  calculateSavings(results);

  return results;
}

export function calculateResultsForDataRow(countryCode, type, name, value, tonnage, results) {
  if (!results.hasOwnProperty(countryCode)) {
    results[countryCode] = {};
  }
  if (!results[countryCode].hasOwnProperty(name)) {
    results[countryCode][name] = {};
  }
  if (Number.isNaN(tonnage)) {
    tonnage = 0;
  }
  let resultTypeName = type;
  let calculatedValue = value * tonnage * 1000;
  if (name === 'Proportion Leaked to Ocean') {
    resultTypeName = name;
    calculatedValue = value * tonnage;
  }
  if (name === 'Carbon Footprint') {
    calculatedValue = value * tonnage;
  }
  if (!results[countryCode][name].hasOwnProperty(resultTypeName)) {
    results[countryCode][name][resultTypeName] = 0;
  }
  results[countryCode][name][resultTypeName] += calculatedValue;
}

export function calculateSavings(results) {
  Object.entries(results).forEach(([countryCode, countryResults]) => {
    Object.entries(countryResults).forEach(([name, resultRow]) => {
      if (resultRow.hasOwnProperty('Initial') && resultRow.hasOwnProperty('Recycling')) {
        results[countryCode][name]['Savings'] = resultRow['Initial'] - resultRow['Recycling'];
      }
    });
  });
}

export function calculateSavingsPercentagesForType(type, results) {
  const savings = [];
  const countryPercentages = {};
  Object.entries(results).forEach(([countryCode, countryResults]) => {
    savings.push([countryCode, countryResults[type]['Savings']]);
  });
  if (savings.length > 1) {
    savings.sort((a, b) => a[1] - b[1]);
    const maxSavings = savings[savings.length - 1][1];
    savings.forEach(([countryCode, countrySavings], i) => {
      countryPercentages[countryCode] = {
        pct: ((countrySavings / maxSavings) * 100).toFixed(2),
        sortOrder: i + 1,
      }
    });
  }
  return countryPercentages;
}

export function formatNum(num) {
  if (num === 0) {
    return '0';
  }
  if (Math.abs(num) < 1) {
    return num.toPrecision(2);
  }
  if (Math.abs(num) < 10) {
    return num.toPrecision(3);
  }
  return Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

