export const PH = {
  code: "PH",
  name: "Philippines",
  benchmarkOptions: [
    "Default EOL Fates",
    "Sanitary Landfill",
    "Open Dump",
    "Open Burning",
    "Co-processing in Cement Kilns",
  ],      
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],    
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.04877248721475,
          "Energy Consumption": 0.294418359517527,
          "Water Consumption": 0.000422307135544
        },
        "Consequential": {
          "Carbon Footprint": 1.0149682789093,
          "Energy Consumption": -1.90623611584413,
          "Water Consumption": 0.000349885479671
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.112755138964053,
          "Energy Consumption": 0.337061532749664,
          "Water Consumption": 0.0000329439587100107
        },
        "Consequential": {
          "Carbon Footprint": 0.112755138964053,
          "Energy Consumption": 0.337061532749664,
          "Water Consumption": 0.0000329439587100107
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.122433740125819,
          "Energy Consumption": 0.027442593899805,
          "Water Consumption": 0.00000224353679807689
        },
        "Consequential": {
          "Carbon Footprint": 0.122433740125819,
          "Energy Consumption": 0.027442593899805,
          "Water Consumption": 0.00000224353679807689
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.66464779618486,
          "Energy Consumption": 0.022608192760167,
          "Water Consumption": 0.000988215900742
        },
        "Consequential": {
          "Carbon Footprint": 2.66464779618486,
          "Energy Consumption": 0.022608192760167,
          "Water Consumption": 0.000988215900742
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.978130679350537,
          "Energy Consumption": 4.61502633185001,
          "Water Consumption": 0.00149039286923
        },
        "Consequential": {
          "Carbon Footprint": 0.27156730903169,
          "Energy Consumption": -41.3822598334961,
          "Water Consumption": -0.0000233384625414217
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.278281597057561,
          "Energy Consumption": 3.89003277887565,
          "Water Consumption": 0.002125719857022
        },
        "Consequential": {
          "Carbon Footprint": -0.698885123988019,
          "Energy Consumption": -35.5387672211244,
          "Water Consumption": -0.005551469454654
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.24882471028456,
          "Energy Consumption": 0.319346901586202,
          "Water Consumption": 0.000429774100611
        },
        "Consequential": {
          "Carbon Footprint": 1.24882471028456,
          "Energy Consumption": 0.319346901586202,
          "Water Consumption": 0.000429774100611
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.133210617710777,
          "Energy Consumption": 0.36681048913498,
          "Water Consumption": 0.0000348951244516626
        },
        "Consequential": {
          "Carbon Footprint": 0.133210617710777,
          "Energy Consumption": 0.36681048913498,
          "Water Consumption": 0.0000348951244516626
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.14681644104703,
          "Energy Consumption": 0.05601602445367,
          "Water Consumption": 0.00000399199944019449
        },
        "Consequential": {
          "Carbon Footprint": 0.14681644104703,
          "Energy Consumption": 0.05601602445367,
          "Water Consumption": 0.00000399199944019449
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.13352729802036,
          "Energy Consumption": 0.051368699695872,
          "Water Consumption": 0.000987482440062
        },
        "Consequential": {
          "Carbon Footprint": 3.13352729802036,
          "Energy Consumption": 0.051368699695872,
          "Water Consumption": 0.000987482440062
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.980338355004759,
          "Energy Consumption": 4.64422351015206,
          "Water Consumption": 0.001492201666378
        },
        "Consequential": {
          "Carbon Footprint": 0.980338355004759,
          "Energy Consumption": 4.64422351015206,
          "Water Consumption": 0.001492201666378
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.305058300753738,
          "Energy Consumption": 4.17060533433388,
          "Water Consumption": 0.002347850374244
        },
        "Consequential": {
          "Carbon Footprint": -0.693696976535947,
          "Energy Consumption": -34.5631426156661,
          "Water Consumption": -0.006380534219323
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.10865492983008,
          "Energy Consumption": 0.222107265138795,
          "Water Consumption": 0.000355975304297
        },
        "Consequential": {
          "Carbon Footprint": 1.10865492983008,
          "Energy Consumption": 0.222107265138795,
          "Water Consumption": 0.000355975304297
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.13297781079779,
          "Energy Consumption": 0.363675315899404,
          "Water Consumption": 0.0000346873518021343
        },
        "Consequential": {
          "Carbon Footprint": 0.13297781079779,
          "Energy Consumption": 0.363675315899404,
          "Water Consumption": 0.0000346873518021343
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.147154023066666,
          "Energy Consumption": 0.061697304130443,
          "Water Consumption": 0.00000463703360966718
        },
        "Consequential": {
          "Carbon Footprint": 0.147154023066666,
          "Energy Consumption": 0.061697304130443,
          "Water Consumption": 0.00000463703360966718
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.133256191838,
          "Energy Consumption": 0.047641537931612,
          "Water Consumption": 0.000987217404944
        },
        "Consequential": {
          "Carbon Footprint": 3.133256191838,
          "Energy Consumption": 0.047641537931612,
          "Water Consumption": 0.000987217404944
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.980130745748846,
          "Energy Consumption": 4.64147781494523,
          "Water Consumption": 0.001492031567555
        },
        "Consequential": {
          "Carbon Footprint": 0.980130745748846,
          "Energy Consumption": 4.64147781494523,
          "Water Consumption": 0.001492031567555
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0,
          "Energy Consumption": 0,
          "Water Consumption": 0
        },
        "Consequential": {
          "Carbon Footprint": 0,
          "Energy Consumption": 0,
          "Water Consumption": 0
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.869259660919005,
          "Energy Consumption": 0.675591817670777,
          "Water Consumption": 0.00034594074906
        },
        "Consequential": {
          "Carbon Footprint": 0.869259660919005,
          "Energy Consumption": 0.675591817670777,
          "Water Consumption": 0.00034594074906
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.100098608078798,
          "Energy Consumption": 0.427031914955205,
          "Water Consumption": 0.0000393393553454081
        },
        "Consequential": {
          "Carbon Footprint": 0.100098608078798,
          "Energy Consumption": 0.427031914955205,
          "Water Consumption": 0.0000393393553454081
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.10353478763282,
          "Energy Consumption": 0.09834177935155,
          "Water Consumption": 0.00000621290049151027
        },
        "Consequential": {
          "Carbon Footprint": 0.10353478763282,
          "Energy Consumption": 0.09834177935155,
          "Water Consumption": 0.00000621290049151027
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.15896659498651,
          "Energy Consumption": 0.115535286751341,
          "Water Consumption": 0.000516916034287
        },
        "Consequential": {
          "Carbon Footprint": 2.15896659498651,
          "Energy Consumption": 0.115535286751341,
          "Water Consumption": 0.000516916034287
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.984411044514518,
          "Energy Consumption": 4.69808606082063,
          "Water Consumption": 0.001495538510338
        },
        "Consequential": {
          "Carbon Footprint": 0.984411044514518,
          "Energy Consumption": 4.69808606082063,
          "Water Consumption": 0.001495538510338
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.312997342517875,
          "Energy Consumption": 4.26402605370588,
          "Water Consumption": 0.002385858568557
        },
        "Consequential": {
          "Carbon Footprint": -1.13543423589745,
          "Energy Consumption": -35.9589977462941,
          "Water Consumption": -0.021469009643089
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 1.04382017025809,
          "Energy Consumption": 0.272523651530831,
          "Water Consumption": 0.000359580101598
        },
        "Consequential": {
          "Carbon Footprint": 1.03490795314503,
          "Energy Consumption": -0.307661818220137,
          "Water Consumption": 0.000340486694204
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.118575842069958,
          "Energy Consumption": 0.368875820417782,
          "Water Consumption": 0.0000351394371490352
        },
        "Consequential": {
          "Carbon Footprint": 0.118575842069958,
          "Energy Consumption": 0.368875820417782,
          "Water Consumption": 0.0000351394371490352
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.12866387074736,
          "Energy Consumption": 0.056475120920452,
          "Water Consumption": 0.00000400369235521428
        },
        "Consequential": {
          "Carbon Footprint": 0.12866387074736,
          "Energy Consumption": 0.056475120920452,
          "Water Consumption": 0.00000400369235521428
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.75151785671845,
          "Energy Consumption": 0.054527451654213,
          "Water Consumption": 0.000883642075014
        },
        "Consequential": {
          "Carbon Footprint": 2.75151785671845,
          "Energy Consumption": 0.054527451654213,
          "Water Consumption": 0.000883642075014
        }
      },
      "Co-processing in Cement Kilns": {
        "Attributional": {
          "Carbon Footprint": 0.98040948260247,
          "Energy Consumption": 4.64516419414637,
          "Water Consumption": 0.001492259942781
        },
        "Consequential": {
          "Carbon Footprint": 0.729547074975503,
          "Energy Consumption": -11.6859823618068,
          "Water Consumption": 0.000954815882785
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.230417741904741,
          "Energy Consumption": 3.17717243479307,
          "Water Consumption": 0.001762583608391
        },
        "Consequential": {
          "Carbon Footprint": -0.64110332073593,
          "Energy Consumption": -27.639635022684,
          "Water Consumption": -0.008023219388848
        }
      }
    }
  }
};
