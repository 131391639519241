export const TH = {
  code: "TH",
  name: "Thailand",
  benchmarkOptions: [
    "Default EOL Fates",
    "Sanitary Landfill",
    "Open Dump",
    "Open Burning",
    "Incineration",
  ],
  scopeOptions: [
    "Attributional",
    "Consequential",
  ],
  data: {
    "PP": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.748978478834005,
          "Energy Consumption": 0.254007978849421,
          "Water Consumption": 0.0002858318732686
        },
        "Consequential": {
          "Carbon Footprint": 0.707752085353675,
          "Energy Consumption": -0.400568698840611,
          "Water Consumption": -0.000132304653704194
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.127217165442113,
          "Energy Consumption": 0.543470611811882,
          "Water Consumption": 0.0000494351790240165
        },
        "Consequential": {
          "Carbon Footprint": 0.127217165442113,
          "Energy Consumption": 0.543470611811882,
          "Water Consumption": 0.0000494351790240165
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.132706415431514,
          "Energy Consumption": 0.169170318178224,
          "Water Consumption": 0.000012437348184389
        },
        "Consequential": {
          "Carbon Footprint": 0.132706415431514,
          "Energy Consumption": 0.169170318178224,
          "Water Consumption": 0.000012437348184389
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.6715925945575,
          "Energy Consumption": 0.112897208114979,
          "Water Consumption": 0.000993434096405088
        },
        "Consequential": {
          "Carbon Footprint": 2.6715925945575,
          "Energy Consumption": 0.112897208114979,
          "Water Consumption": 0.000993434096405088
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.59454231049377,
          "Energy Consumption": 0.732700339786899,
          "Water Consumption": 0.00200446084915938
        },
        "Consequential": {
          "Carbon Footprint": 0.983258021141618,
          "Energy Consumption": -24.8506463442131,
          "Water Consumption": -0.0143379052866752
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.25529070107307,
          "Energy Consumption": 3.66389552584225,
          "Water Consumption": 0.00186825581306865
        },
        "Consequential": {
          "Carbon Footprint": -0.726984155789234,
          "Energy Consumption": -35.8065714241578,
          "Water Consumption": -0.00583454968806255
        }
      }
    },
    "HDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.876987752006178,
          "Energy Consumption": 0.26956626344774,
          "Water Consumption": 0.000281777149662731
        },
        "Consequential": {
          "Carbon Footprint": 0.828172771106235,
          "Energy Consumption": -0.505586937632916,
          "Water Consumption": -0.000213005320773723
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.14806130661744,
          "Energy Consumption": 0.578195853398883,
          "Water Consumption": 0.0000516634374928196
        },
        "Consequential": {
          "Carbon Footprint": 0.14806130661744,
          "Energy Consumption": 0.578195853398883,
          "Water Consumption": 0.0000516634374928196
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.156303278064513,
          "Energy Consumption": 0.184576619363572,
          "Water Consumption": 0.0000127018374056325
        },
        "Consequential": {
          "Carbon Footprint": 0.156303278064513,
          "Energy Consumption": 0.184576619363572,
          "Water Consumption": 0.0000127018374056325
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.14095763744185,
          "Energy Consumption": 0.1481421872788,
          "Water Consumption": 0.0009931175423991
        },
        "Consequential": {
          "Carbon Footprint": 3.14095763744185,
          "Energy Consumption": 0.1481421872788,
          "Water Consumption": 0.0009931175423991
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.06941653710076,
          "Energy Consumption": 0.818925214161865,
          "Water Consumption": 0.00201269401103874
        },
        "Consequential": {
          "Carbon Footprint": 0.95182790608687,
          "Energy Consumption": -32.8071374338381,
          "Water Consumption": -0.0194509164402032
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.257955666900541,
          "Energy Consumption": 3.69914050500607,
          "Water Consumption": 0.0018704392780989
        },
        "Consequential": {
          "Carbon Footprint": -0.747079792948049,
          "Energy Consumption": -35.0858161449939,
          "Water Consumption": -0.0068894390642662
        }
      }
    },
    "LDPE": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.870421661020831,
          "Energy Consumption": 0.297772881678147,
          "Water Consumption": 0.000285879403551616
        },
        "Consequential": {
          "Carbon Footprint": 0.813389615351825,
          "Energy Consumption": -0.60786247758016,
          "Water Consumption": -0.000292190195778861
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.148209336304584,
          "Energy Consumption": 0.579976300874675,
          "Water Consumption": 0.000051731031878453
        },
        "Consequential": {
          "Carbon Footprint": 0.148209336304584,
          "Energy Consumption": 0.579976300874675,
          "Water Consumption": 0.000051731031878453
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.157705163586198,
          "Energy Consumption": 0.205737809157201,
          "Water Consumption": 0.0000146441119342062
        },
        "Consequential": {
          "Carbon Footprint": 0.157705163586198,
          "Energy Consumption": 0.205737809157201,
          "Water Consumption": 0.0000146441119342062
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 3.1411851119061,
          "Energy Consumption": 0.151150605874675,
          "Water Consumption": 0.000993303917223437
        },
        "Consequential": {
          "Carbon Footprint": 3.1411851119061,
          "Energy Consumption": 0.151150605874675,
          "Water Consumption": 0.000993303917223437
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 3.06973391069235,
          "Energy Consumption": 0.823323195874675,
          "Water Consumption": 0.00201301479693671
        },
        "Consequential": {
          "Carbon Footprint": 0.952145279678461,
          "Energy Consumption": -32.8027394521253,
          "Water Consumption": -0.0194505956543052
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0,
          "Energy Consumption": 0,
          "Water Consumption": 0
        },
        "Consequential": {
          "Carbon Footprint": 0,
          "Energy Consumption": 0,
          "Water Consumption": 0
        }
      }
    },
    "PET": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.608974497610045,
          "Energy Consumption": 0.245517329865655,
          "Water Consumption": 0.000173474843041225
        },
        "Consequential": {
          "Carbon Footprint": 0.575865869531126,
          "Energy Consumption": -0.279885343784124,
          "Water Consumption": -0.000163362125224673
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.106198961588965,
          "Energy Consumption": 0.522504638763283,
          "Water Consumption": 0.0000489731103233491
        },
        "Consequential": {
          "Carbon Footprint": 0.106198961588965,
          "Energy Consumption": 0.522504638763283,
          "Water Consumption": 0.0000489731103233491
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.106608914725901,
          "Energy Consumption": 0.147243529963985,
          "Water Consumption": 0.0000112286231604199
        },
        "Consequential": {
          "Carbon Footprint": 0.106608914725901,
          "Energy Consumption": 0.147243529963985,
          "Water Consumption": 0.0000112286231604199
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.1570093153631,
          "Energy Consumption": 0.0866670143817432,
          "Water Consumption": 0.000514409139121279
        },
        "Consequential": {
          "Carbon Footprint": 2.1570093153631,
          "Energy Consumption": 0.0866670143817432,
          "Water Consumption": 0.000514409139121279
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.10681213664756,
          "Energy Consumption": 0.752830854880159,
          "Water Consumption": 0.00152048272521441
        },
        "Consequential": {
          "Carbon Footprint": 0.963696462174093,
          "Energy Consumption": -17.3873358179198,
          "Water Consumption": -0.010109223502693
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.253307366947035,
          "Energy Consumption": 3.63766533210902,
          "Water Consumption": 0.00186663082386134
        },
        "Consequential": {
          "Carbon Footprint": -1.19800861179161,
          "Energy Consumption": -36.608877917891,
          "Water Consumption": -0.0220027020276835
        }
      }
    },
    "Generic": {
      "Default EOL Fates": {
        "Attributional": {
          "Carbon Footprint": 0.804051672055424,
          "Energy Consumption": 0.26709306570697,
          "Water Consumption": 0.000256824333947505
        },
        "Consequential": {
          "Carbon Footprint": 0.758837275041048,
          "Energy Consumption": -0.450782392661639,
          "Water Consumption": -0.000201837696509542
        }
      },
      "Sanitary Landfill": {
        "Attributional": {
          "Carbon Footprint": 0.132941741162863,
          "Energy Consumption": 0.558008397106422,
          "Water Consumption": 0.0000506271041925879
        },
        "Consequential": {
          "Carbon Footprint": 0.132941741162863,
          "Energy Consumption": 0.558008397106422,
          "Water Consumption": 0.0000506271041925879
        }
      },
      "Open Dump": {
        "Attributional": {
          "Carbon Footprint": 0.138941764825696,
          "Energy Consumption": 0.17953966594994,
          "Water Consumption": 0.0000129650547622681
        },
        "Consequential": {
          "Carbon Footprint": 0.138941764825696,
          "Energy Consumption": 0.17953966594994,
          "Water Consumption": 0.0000129650547622681
        }
      },
      "Open Burning": {
        "Attributional": {
          "Carbon Footprint": 2.78633978133235,
          "Energy Consumption": 0.126564218816025,
          "Water Consumption": 0.000843037644237313
        },
        "Consequential": {
          "Carbon Footprint": 2.78633978133235,
          "Energy Consumption": 0.126564218816025,
          "Water Consumption": 0.000843037644237313
        }
      },
      "Incineration": {
        "Attributional": {
          "Carbon Footprint": 2.72098947300882,
          "Energy Consumption": 0.791437586717207,
          "Water Consumption": 0.00185759170139833
        },
        "Consequential": {
          "Carbon Footprint": 0.958755528523466,
          "Energy Consumption": -27.1878031902397,
          "Water Consumption": -0.0160187895508164
        }
      },
      "Recycling": {
        "Attributional": {
          "Carbon Footprint": 0.156450785853111,
          "Energy Consumption": 2.24545554552032,
          "Water Consumption": 0.00114548599162319
        },
        "Consequential": {
          "Carbon Footprint": -0.597583667806666,
          "Energy Consumption": -22.0620107095148,
          "Water Consumption": -0.00886238386662131
        }
      }
    }
  }
};
